import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const post_Login = (params, successCallabck, errorCallback) => {
    apiClient(false).post(API.LOGIN, params).then(resp => {
        if (resp.status === 200) {
            successCallabck(resp.data)
        }else {
            errorCallback(resp.data)
        }
    }).catch(err => {
        errorCallback(err.data)
    })
}

const post_ForgotPassword = (params, successCallabck, errorCallback) => {
    apiClient(false).post(API.FORGOT_PASSWORD, params).then(resp => {
        if (resp.status === 200) {
            successCallabck(resp.data)
        } else {
            errorCallback(resp.data)
        }
    }).catch(err =>{
        errorCallback(err.data)
    })
}

const post_CheckOTP = (params, successCallabck, errorCallback) => {
    apiClient(false).post(API.CHECK_OTP, params).then(resp => {
        if (resp.status === 200) {
            successCallabck(resp.data)
        } else {
            errorCallback(resp.data)
        }
    }).catch(err =>{
        errorCallback(err.data)
    })
}

const post_UpdatePassword = (params, successCallabck, errorCallback) => {
    apiClient(false).post(API.UPDATE_PASSWORD, params).then(resp => {
        if (resp.status === 200) {
            successCallabck(resp.data)
        } else {
            errorCallback(resp.data)
        }
    }).catch(err =>{
        errorCallback(err.data)
    })
}

export {
    post_Login,
    post_ForgotPassword,
    post_CheckOTP,
    post_UpdatePassword
}